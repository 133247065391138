import styled from 'styled-components';

export interface Props {
  size?: number;
}

export const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
`;

export const LogoImage = styled.img<Props>`
  width: ${({ size = 200 }) => `${size}px`};
  height: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
