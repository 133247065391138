import { RoutePaths } from 'core/models';
import { MenuItem } from 'hudskit-framework-react';
import NavMenuItem from '../NavMenuItem';

const getUptimeMenu = () => {
  const uptimeMenuMap = new Map<RoutePaths, MenuItem>([
    [
      RoutePaths.Dashboard,
      {
        id: 1,
        label: <NavMenuItem id="dashboard_menu" labelKey="CORE:menu.dashboard" path={RoutePaths.Dashboard} />,
        link: RoutePaths.Dashboard,
      },
    ],
    [
      RoutePaths.Assets,
      {
        id: 2,
        label: <NavMenuItem id="asset_menu" labelKey="CORE:menu.assets" path={RoutePaths.Assets} />,
        link: RoutePaths.Assets,
      },
    ],
    [
      RoutePaths.Companies, {
        id: 3,
        label: <NavMenuItem id="company_menu" labelKey="CORE:menu.companies" path={RoutePaths.Companies} />,
        link: RoutePaths.Companies,
      },
    ],
    [
      RoutePaths.AssetsMap,
      {
        id: 4,
        label: <NavMenuItem id="map_menu" labelKey="CORE:menu.map" path={RoutePaths.AssetsMap} />,
        link: RoutePaths.AssetsMap,
      },
    ],
  ]);

  return { menuMap: uptimeMenuMap, menu: Array.from(uptimeMenuMap.values()).sort((a, b) => a.id - b.id) };
};

const getAssetTabMenu = () => {
  const assetTabMenuMap = new Map<RoutePaths, MenuItem>([
    [
      RoutePaths.AssetDetailProductInfomation,
      {
        id: 1,
        label: <NavMenuItem
          id="product_information_menu"
          labelKey="ASSETS:details.labels.productInfo"
          path={RoutePaths.AssetDetailProductInfomation}
        />,
        link: RoutePaths.AssetDetailProductInfomation,
      },
    ],
    [
      RoutePaths.AssetDetailContacts,
      {
        id: 2,
        label: <NavMenuItem id="conatact_menu" labelKey="ASSETS:details.labels.contact" path={RoutePaths.AssetDetailContacts} />,
        link: RoutePaths.AssetDetailContacts,
      },
    ],
  ]);

  return { menuMap: assetTabMenuMap, menu: Array.from(assetTabMenuMap.values()) };
};

const getDropdownMenu = () => {
  const assetDropdownMenuMap = new Map<RoutePaths, MenuItem>([
    [
      RoutePaths.Faq,
      {
        id: 1,
        label: <NavMenuItem
          id="faq_menu"
          labelKey="CORE:menu.dropdown.faq"
          path={RoutePaths.Faq}
        />,
        iconLeft: 'action.customer_support',
        link: RoutePaths.Faq,
      },
    ],
  ]);

  return { menuMap: assetDropdownMenuMap, menu: Array.from(assetDropdownMenuMap.values()) };
};

export { getUptimeMenu, getAssetTabMenu, getDropdownMenu };
