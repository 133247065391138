export enum RoutePaths {
  // Dashboard Route
  Dashboard = '/',

  // Assets Routes
  Assets = 'assets',
  AssetDetailProductInfomation = 'product-information',
  AssetDetailContacts = 'contacts',

  // Companies Routes
  Companies = 'companies',

  // Uptime Routes
  AssetsMap = 'uptime',

  // Auth Routes
  UserLogin = 'auth',
  UserInfo = 'user',

  // Generic Routes
  NotFound = '*',
  Faq = 'faq',
  Details = ':id',
}
