/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AssetDetailsResponse, AssetListItem, AssetListRequest, AssetsSearchProps } from '@uptime-bff/api-contract';

type ListFiltersType = AssetListRequest['filters'];
type ListSortingType = AssetListRequest['sorting'];
type ListFilterSearchType = {
  query: string;
  fields: AssetsSearchProps[];
};

export const defaultState: AssetListState = {
  activeListFilters: undefined,
  assetList: {},
  assetListSorting: undefined,
  assetMapActiveAssetId: undefined,
  assetSearch: {
    query: '',
    fields: [AssetsSearchProps.AssetName],
  },
  selectedAsset: undefined,
};

interface AssetListState {
  assetSearch?: ListFilterSearchType;
  activeListFilters: ListFiltersType;
  assetList: { [key: string]: AssetListItem };
  assetListSorting?: ListSortingType;
  assetMapActiveAssetId?: string;
  selectedAsset?: AssetListItem;
}

const assetListToMap = (list: AssetListItem[]): { [key: string]: AssetListItem } => {
  const payloadAssetsMap: { [key: string]: AssetListItem } = {};
  for (const asset of list) {
    payloadAssetsMap[asset.id] = asset;
  }
  return payloadAssetsMap;
};

const slice = createSlice({
  name: 'assets',
  initialState: defaultState,
  reducers: {
    setSelectedAsset: (state, action: PayloadAction<AssetListItem | undefined>) => {
      state.selectedAsset = action.payload;
    },

    setSortingOnActiveSearch: (state, action: PayloadAction<ListSortingType>) => {
      state.assetListSorting = action.payload;
    },

    setFilterByIdOnActiveSearch: (state, action: PayloadAction<string>) => {
      state.activeListFilters = { ...state.activeListFilters, id: action.payload };
    },
    setFilterByModelsOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        models: action.payload.length > 0 ? action.payload : undefined,
      };
    },
    setFuzzySearchQuery: (state, action: PayloadAction<string>) => {
      state.assetSearch = {
        fields: state.assetSearch?.fields ?? [AssetsSearchProps.AssetName],
        query: action.payload,
      };
      slice.caseReducers.setSearchOnActiveSearch(state);
    },

    setFuzzySearchField: (state, action: PayloadAction<{ field: AssetsSearchProps; selected: boolean; value: string }>) => {
      state.assetSearch = {
        fields: action.payload.selected ? [action.payload.field] : [AssetsSearchProps.AssetName],
        query: state.assetSearch?.query ?? '',
      };

      slice.caseReducers.setSearchOnActiveSearch(state);
    },

    setSearchOnActiveSearch: (state) => {
      if (
        state.assetSearch?.query.length
        && state.assetSearch?.query.length > 1
        && state.assetSearch?.fields.length
        && state.assetSearch?.fields.length > 0
      ) {
        state.activeListFilters = {
          ...state.activeListFilters,
          search: state.assetSearch,
        };
      } else {
        state.activeListFilters = {
          ...state.activeListFilters,
          search: undefined,
        };
      }
    },

    clearFuzzySearchOnActiveSearch: (state) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        search: undefined,
      };
      state.assetSearch = {
        fields: [AssetsSearchProps.AssetName],
        query: '',
      };
    },

    setFilterByCompaniesOnActiveSearch: (
      state,
      action: PayloadAction<{ diamGroupId: string; companyName: string }>
    ) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        diamGroupId: [action.payload.diamGroupId],
        companies: [action.payload.companyName],
      };
    },
    setFilterByCompanyCountryOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        companyCountries: action.payload.length > 0 ? action.payload : undefined,
      };
    },
    setFilterByCountriesOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        companyCountries: action.payload,
      };
    },
    setFilterByStatusOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        secondaryStatus: action.payload.length > 0 ? action.payload : undefined,
      };
    },

    setFilterByOperationalStatusOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        operationalStatus: action.payload.length > 0 ? action.payload : undefined,
      };
    },

    appendAssetsToList: (state, action: PayloadAction<AssetListItem[]>) => {
      state.assetList = { ...state.assetList, ...assetListToMap(action.payload) };
    },
    updateAssetInList: (state, action: PayloadAction<AssetDetailsResponse>) => {
      state.assetList = {
        ...state.assetList,
        [action.payload.iprId]: {
          id: action.payload.iprId,
          name: action.payload.assetName,
          company: action.payload.companyName
            ? action.payload.companyName
            : state.assetList[action.payload.iprId].company,
          ...action.payload,
          status: {
            errorCode: action.payload.status.infoItems?.[0]?.metadata?.errorCode,
            secondaryStatus: action.payload.status.secondaryStatusTranslationKey,
            secondarySubStatus: action.payload.status.secondaryStatusSubStatusTranslationKey,
            operationalStatus: action.payload.status.operationalStatus,
          },
        },
      };
    },

    setAssetMapActiveAssetId: (state, action: PayloadAction<string | undefined>) => {
      state.assetMapActiveAssetId = action.payload;
    },
    setAssetList: (state, action: PayloadAction<AssetListItem[]>) => {
      state.assetList = assetListToMap(action.payload);
    },
    clearFilters: (state) => {
      state.activeListFilters = undefined;
      state.assetListSorting = undefined;
      state.assetSearch = {
        fields: [AssetsSearchProps.AssetName],
        query: '',
      };
    },
    clearCompanyNameFilters: (state) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        companies: undefined,
        diamGroupId: undefined,
      };
    },
  },
});

export const {
  setSelectedAsset,
  setFilterByCompaniesOnActiveSearch,
  setFilterByCountriesOnActiveSearch,
  setFilterByIdOnActiveSearch,
  setFilterByStatusOnActiveSearch,
  setFilterByModelsOnActiveSearch,
  setFilterByOperationalStatusOnActiveSearch,
  appendAssetsToList,
  setAssetList,
  updateAssetInList,
  setAssetMapActiveAssetId,
  clearFilters,
  clearCompanyNameFilters,
  setFilterByCompanyCountryOnActiveSearch,
  clearFuzzySearchOnActiveSearch,
  setSortingOnActiveSearch,
  setFuzzySearchField,
  setFuzzySearchQuery,
  setSearchOnActiveSearch,
} = slice.actions;
export default slice.reducer;
