import { useCallback, useEffect, useRef, useState } from 'react';

export const useDropdownMenuClick = () => {

  const [dropdownMenu, setDropdownMenu] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const displayDropdownMenu = useCallback(() => {
    setDropdownMenu((open) => !open);
  }, [setDropdownMenu]);

  const closeDropdownMenu = useCallback((e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setDropdownMenu(false);
    }
  }, []);

  useEffect(() => {
    if (dropdownMenu) {
      document.addEventListener('mousedown', closeDropdownMenu);
    }
    return () => {
      document.removeEventListener('mousedown', closeDropdownMenu);
    };
  }, [closeDropdownMenu, dropdownMenu]);

  return { dropdownMenu, dropdownRef, displayDropdownMenu };
};
