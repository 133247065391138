import { Button, NavBar, darkTheme } from 'hudskit-framework-react';
import uptimeLogoDark from 'images/logos/uptime-logo-dark.svg';
import uptimeLogoLight from 'images/logos/uptime-logo-light.svg';
import { useTheme } from 'styled-components';
import { useDropdownMenuClick } from 'core/hooks/use-dropdown-menu-click';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import useMediaQuery from 'core/hooks/use-media-query';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { device } from 'core/styles/variables';
import { RoutePaths } from 'core/models';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavMenu from '../NavMenu/NavMenu';
import MobileNavMenu from '../MobileNavMenu';
import * as S from './styled';
import MenuWithDropdown from '../MenuWithDropdown';

const Header = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(device.lg);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);

  const { dropdownMenu, dropdownRef, displayDropdownMenu } = useDropdownMenuClick();
  const isFaqPage = location.pathname.toLowerCase() === `/${RoutePaths.Faq}`;
  const logo = {
    src: theme === darkTheme ? uptimeLogoDark : uptimeLogoLight,
    alt: t('CORE:logo.logoDesc'),
    height: 50,
    width: 180,
    style: { paddingBottom: '0.4rem' },
  };
  const logoMobile = {
    src: theme === darkTheme ? uptimeLogoDark : uptimeLogoLight,
    alt: t('CORE:logo.logoDesc'),
    height: 50,
    width: 150,
    style: { maxWidth: '95%' },
  };
  const userSettingsOnClickHanlder = useCallback(() => {
    navigate(RoutePaths.UserInfo);
  }, [navigate]);
  return (
    <NavBar
      rightSideArea={(
        <S.RightSideAreaDiv ref={dropdownRef}>
          {!isFaqPage && (
          <>
            <S.DropdownContainerDiv id="utcInfoIcon">
              <Button
                variant="transparent"
                size="small"
                onClick={displayDropdownMenu}
                icon={{ type: dropdownMenu ? 'notification.info' : 'notification.info_outlined', position: 'left' }}
              />
            </S.DropdownContainerDiv>
            {dropdownMenu && (
            <S.DropdownDiv data-testid="dropdown">
              <MenuWithDropdown />
            </S.DropdownDiv>
            )}
          </>
          )}
          <div id="utcAvatar">
            <Button
              variant="transparent"
              size="small"
              onClick={userSettingsOnClickHanlder}
              icon={{ type: 'action.avatar_outlined', position: 'left' }}
            />
          </div>
        </S.RightSideAreaDiv>
        )}
      logoLink={RoutePaths.Dashboard}
      primaryArea={isDesktop && <NavMenu />}
      desktopLogo={logo}
      mobileLogo={logoMobile}
      leftSideArea={!isDesktop && <MobileNavMenu />}
    />
  );
};
export default Header;
