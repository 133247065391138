import { NavMenuDropdown } from 'hudskit-framework-react';
import { useMemo } from 'react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import { getDropdownMenu } from '../NavMenu/menu';

const MenuWithDropdown = () => {
  const dropdownMenu = useMemo(() => getDropdownMenu(), []);
  const { activeDropdownMenu, onClickHandler } = useUptimeNavigation(dropdownMenu.menu);

  return (
    <NavMenuDropdown
      data-testid="menu-with-dropdown"
      activeMenuItemId={activeDropdownMenu}
      onClick={onClickHandler}
      menu={dropdownMenu.menu}
    />
  );
};

export default MenuWithDropdown;
