import { SecondaryStatusRoboticMowerEnum } from '@uptime-bff/api-contract';
import { breakpoints, darkTheme } from 'hudskit-framework-react';
import { DefaultTheme } from 'styled-components';

export const colors = {
  husqvarnaBlue: '#273A60',
};

/**
 * Get css color of the combination of two stacked colors
 * @param colorFront Top color, normally used for success, error, caution, progress, etc.
 * @param colorBack Back color, normally used for background or surface color
 * @param opacity Opacity Level by default 50%
 * @returns CSS for the color mix
 */
export const colorMix = (colorFront: string, colorBack: string, opacity = 50) =>
  `color-mix(in srgb, ${colorFront} ${opacity}%,  ${colorBack})`;

export const paddings = {
  hugePadding: '32px',
  largePadding: '20px',
  defaultPadding: '16px',
  mediumPadding: '12px',
  smallPadding: '6px',
  tinyPadding: '4px',
};

export const borderRadius = {
  default: '16px',
};

export const device = {
  xs: `(max-width: ${breakpoints.s}px)`,
  sm: `(min-width: ${breakpoints.s}px)`,
  md: `(min-width: ${breakpoints.m}px)`,
  lg: `(min-width: ${breakpoints.l}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px)`,
};

const typography = {
  type: {
    primary: '"Husqvarna Gothic", "Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  weight: {
    light: '300',
    regular: '400',
    bold: '700',
  },
};

export const typographyMobile = {
  ...typography,
  size: {
    large: '20px',
    medium: '18px',
    default: '16px',
    small: '14px',
    tiny: '12px',
    h1: '40px',
    h2: '28px',
    h3: '24px',
    h4: '20px',
    h5: '18px',
    h6: '16px',
  },
};

export const typographyDesktop = {
  ...typography,
  size: {
    large: '22px',
    medium: '20px',
    default: '18px',
    small: '16px',
    tiny: '14px',
    h1: '46px',
    h2: '34px',
    h3: '30px',
    h4: '26px',
    h5: '24px',
    h6: '22px',
  }
};

export const headerHeight = '77px';

export type FunctionalColor = 'error' | 'caution' | 'success' | 'progress' | 'connectivity' | 'other' | 'none';
/**
 * Gets the RGB color for the given functional color
 * @param theme Current active theme
 * @param color Functional Color to render
 * @returns RGB Color from theme from the specified Functional Color
 */
export const getFunctionalColor = (theme: DefaultTheme, color?: FunctionalColor) => {
  switch (color) {
    case 'error':
      return theme.colors.error;
    case 'caution':
      return theme.colors.caution;
    case 'success':
      return theme.colors.success;
    case 'progress':
      return theme.colors.progress;
    case 'connectivity':
      return theme.colors.connectivity;
    case 'other':
      return theme === darkTheme ? theme.colors.gray200 : theme.colors.gray400;
    default:
      return 'inherit';
  }
};

/**
 * Gets the appropriate background color for the given combination of functional color and surface/background color
 * @param theme Current active theme
 * @param color Functional color to render
 * @param surface Render on a surface or on background
 * @param opacity Opacity percentage
 * @returns CSS color Mix with the provided functional color and the background
 */
export const getFunctionalLightColor = (theme: DefaultTheme, color?: FunctionalColor, surface = true, opacity = 50) => {
  const functionalColor = getFunctionalColor(theme, color);
  return colorMix(functionalColor, surface ? theme.colors.surface : theme.colors.background, opacity);
};

export const getForegroundFunctionalColor = (theme: DefaultTheme, color?: FunctionalColor) => {
  switch (color) {
    case 'error':
    case 'success':
    case 'progress':
    case 'connectivity':
      return theme === darkTheme ? theme.colors.gray300 : theme.colors.gray200;
    case 'caution':
      return theme === darkTheme ? theme.colors.gray600 : theme.colors.gray700;
    default:
      return 'inherit';
  }
};

/**
 * Gets the RGB color for the given Status color
 * @param theme Current active theme
 * @param color Functional Color base on Status to render
 * @returns RGB Color from theme from the specified Functional Color
 */
export const getColorBasedOnStatus = (theme: DefaultTheme, status: SecondaryStatusRoboticMowerEnum) => {
  switch (status) {
    case SecondaryStatusRoboticMowerEnum.ERROR:
    case SecondaryStatusRoboticMowerEnum.THEFT_ALARM:
      return theme.colors.error;
    case SecondaryStatusRoboticMowerEnum.STOPPED:
    case SecondaryStatusRoboticMowerEnum.PAUSED:
      return theme.colors.caution;
    case SecondaryStatusRoboticMowerEnum.MOWING:
    case SecondaryStatusRoboticMowerEnum.TRANSPORTING:
      return theme.colors.success;
    case SecondaryStatusRoboticMowerEnum.CHARGING:
    case SecondaryStatusRoboticMowerEnum.PARKED:
      return theme.colors.progress;
    case SecondaryStatusRoboticMowerEnum.NO_CONNECTION:
    case SecondaryStatusRoboticMowerEnum.TURNED_OFF:
    case SecondaryStatusRoboticMowerEnum.PREPARING:
      return theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200;
    default:
      return 'inherit';
  }
};

export const displayDateTimeFormat = 'yyyy-MM-dd HH:mm';
