import { ReactNode } from 'react';
import { Button, Icon, IconTypeKey } from 'hudskit-framework-react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

type Props = {
  icon?: IconTypeKey;
  onRetryClick?: () => void;
  children?: ReactNode;
};
const ErrorCard = ({
  icon = 'product.cloud_disabled',
  onRetryClick = undefined, children =
  undefined }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Core);
  return (
    <S.BackgroundDiv>
      <Icon type={icon} size="extraLarge" />
      <S.MessageDiv>{children ?? <>{t('CORE:errors.genericTryAgain')}</>}</S.MessageDiv>
      {onRetryClick && (
        <Button variant="secondary" onClick={onRetryClick}>
          {t('CORE:buttons.retry')}
        </Button>
      )}
    </S.BackgroundDiv>
  );
};

export default ErrorCard;
