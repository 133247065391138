export enum InternationalizationNameSpace {
  Core = 'CORE',
  Auth = 'AUTH',
  Maintenance = 'MAINTENANCE',
  Assets = 'ASSETS',
  Companies = 'COMPANIES',
  Feedback = 'FEEDBACK',
  Dashboard = 'DASHBOARD',
  Status = 'statusTranslation',
  RoboticMowerCodes = 'MOWER_ERROR_CODES',
  Faq = 'FAQ',
}
