import styled from 'styled-components';

export const RightSideAreaDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const DropdownContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;

export const DropdownDiv = styled.div`
  position: absolute;
  top: 4.6rem;
  right: 4rem;
`;
