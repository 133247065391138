import { useCallback } from 'react';
import { TranslationKeys } from 'core/models/translations.model';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslation } from 'react-i18next';

const useTranslationWrapper = (namespaces:InternationalizationNameSpace | InternationalizationNameSpace[]) => {
  const { t } = useTranslation(namespaces);
  // Cast is a workaround until BE typings are fixed/addressed
  const translate = useCallback((key: TranslationKeys | string) => t(key as TranslationKeys), [t]);
  return { t: translate };
};
export { useTranslationWrapper };
