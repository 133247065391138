import {
  CompaniesDetailsResponse,
  CompaniesRequest,
  CompaniesResponse,
  CompanyCard,
  CompanyFiltersRequest,
  CompanyFiltersResponse,
  TotalDashboardWidgetsResponse
} from '@uptime-bff/api-contract';
import { uptimeApi } from 'core/api';

const companiesApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    searchCompanies: build.query<CompaniesResponse, CompaniesRequest>({
      query: (body) => ({
        url: 'companies',
        method: 'POST',
        body
      }),
    }),

    searchAvailableFilters: build.query<CompanyFiltersResponse, CompanyFiltersRequest>({
      query: (body) => ({
        url: 'companies/filters',
        method: 'POST',
        body,
      }),
    }),

    searchCompaniesById: build.query<CompaniesDetailsResponse, string>({
      query: (companyId) => ({
        url: `companies/${companyId}`,
        method: 'GET',
      })
    }),

    addCompanyWidgetToDashboard: build.mutation<TotalDashboardWidgetsResponse, CompanyCard>({
      query: (body) => ({
        url: 'users/dashboard/company-widget',
        method: 'POST',
        body,
      }),
    }),

    getTotalDashboardWidgets: build.query<TotalDashboardWidgetsResponse, void>({
      query: () => ({
        url: 'users/dashboard/total-widgets',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazySearchCompaniesQuery,
  useSearchCompaniesQuery,
  useSearchAvailableFiltersQuery,
  useSearchCompaniesByIdQuery,
  useAddCompanyWidgetToDashboardMutation,
  useGetTotalDashboardWidgetsQuery,
} = companiesApi;
